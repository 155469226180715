import React, { useState, Fragment } from 'react';
import {
  CardContent,
} from '@material-ui/core';
import Moment from 'moment';
import voca from 'voca';
import styled, { css } from 'styled-components';
import MeetingShowModal from '../meetings/MeetingShowModal';

function getMeetingListDivSizes(hoursHeight, firstHour, lastHour, startAt, finishAt, isDayView) {
  const date = new Moment(startAt);
  const finishDate = new Moment(finishAt);
  const minuteHeight = (hoursHeight - 16) / ((lastHour - firstHour) * 60);
  const dateMinutes = (date.hours() - firstHour) * 60 + date.minutes();
  let meetingMinutes = Moment.duration(finishDate.diff(date)).as('minutes');
  if (meetingMinutes < 15) {
    meetingMinutes = 15;
  }
  return {
    top: `${dateMinutes * minuteHeight}px`,
    // height: isDayView ? '25px' : `${meetingMinutes * minuteHeight}px`,
    height: `${meetingMinutes * minuteHeight}px`,
  };
}

const MeetingWrapper = styled.div`
  background: #943000;
  color: white;
  
  ${props => !props.isDayView && css`
    color: transparent;
    &:hover {
      color: white;
      z-index: 5;
      height: auto !important;
      div {
        display: block;
      }
    }
    div {
      display: none;
    }
  `}
`;

const getMeetingBackgroundColor = ({ isOutside, status }) => {
  if (status !== 'NotSeen') {
    return 'grey';
  }
  return isOutside ? '#EF6F23' : undefined;
};

const CalendarMeeting = (props) => {
  const {
    meeting, dayInfos: { height, firstHour, lastHour }, isDayView,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <Fragment>
      <MeetingShowModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modalRecordId={meeting.id} modalLabel={`Consultation de ${meeting.patient.lastname} ${meeting.patient.firstname}`} />
      <MeetingWrapper
        isDayView={isDayView}
        style={{
          display: 'flex', justifyContent: 'center', background: getMeetingBackgroundColor(meeting), alignItems: 'center', textDecoration: 'none', width: '100%', position: 'absolute', ...getMeetingListDivSizes(height, firstHour, lastHour, meeting.startAt, meeting.finishAt, isDayView),
        }}
        onClick={(e) => { setIsModalOpen(true); e.preventDefault(); e.stopPropagation(); }}
      >
        <CardContent style={{ padding: '0' }}>
          <p style={{ margin: 0, fontSize: '0.88rem' }}>{`${meeting.patient.lastname.toUpperCase()} ${voca.capitalize(meeting.patient.firstname)}`}</p>
          {!isDayView && <br />}
          {!isDayView && <p style={{ margin: 0, fontSize: '0.88rem' }}>{`${voca.capitalize(meeting.type)}`}</p>}
        </CardContent>
      </MeetingWrapper>
    </Fragment>

  );
};

export default CalendarMeeting;
