const listFields = {
  GET_LIST: {
    MeetingList: ['data {id, startAt, finishAt, place {name}, doctors {name}, operators {name}}, total'],
    CalendarDay: ['data {id, meetings {id, isOutside, status, startAt, finishAt, type, operator {id, name}, patient {firstname, lastname}}, meetingLists {id, startAt, finishAt, place {id, name}, doctors {id, name}, operators {name}}, userUnavailabilities {id, title, startAt, finishAt, operator {id, name}}}, total'],
    MeetingPlace: ['data {id, name, city, postalCode, doctors {id, name}}, total'],
    User: ['data {id, name, email}, total'],
    UserUnavailability: ['data {id, title, startAt, finishAt}, total'],
    Patient: ['data {id, firstname, lastname, gender, birthdate, email, phone, secondPhone, address}, total'],
    Doctor: ['data {id, name}, total'],
    DeviceColor: ['data {id, name}, total'],
    Meeting: ['data {id, isOutside, startAt, finishAt, type, isActive, status, patient {id, firstname, lastname}, meetingList {id, place {id, name}, operators {name}}, comment, operator{name}, device {id, type, createdAt}}, total'],
    Device: ['data {id, type, firstColor, secondColor, foamColor, frontThickness, backThickness, correction, codes {code, quantity}, imageType, side, isWithoutTryOn, patient {id, firstname, lastname}, meetings {id, startAt, isOutside, finishAt, type, status, isActive, meetingList {id, place {name}}}, isReadyFitting, hasToBeSeen, isReadyDelivery, isAq, createdAt}, total'],
    DeviceCode: ['data {id, alphanumericCode, numericCode, nomenclature}, total'],
  },
  GET_ONE: {
    MeetingPlace: ['id, name, fullName, address, city, postalCode, country, doctors {id, name}'],
    MeetingList: ['id, startAt, finishAt, place {name}, doctors {id, name}, operators {id, name}, meetings {id, startAt, finishAt, status, type, patient {id, firstname, lastname}, isActive, comment, device {id, type}}, comment'],
    Patient: ['id, firstname, lastname, gender, birthdate, email, phone, comment, meetings {id}, devices {id}, secondPhone, address'],
    Doctor: ['id, name'],
    DeviceColor: ['id, name'],
    User: ['id, name, email, unavailabilities {id, title, startAt, finishAt}'],
    UserUnavailability: ['id, title, startAt, finishAt'],
    Meeting: ['id, isOutside, startAt, finishAt, type, status, patient {id, firstname, lastname, phone}, isActive, comment, meetingList {id, place {id, name}}, device {id, type, createdAt}'],
    Device: ['id,type, operator {id, name}, doctor {id, name}, firstColor, secondColor, foamColor, frontThickness, backThickness, correction, codes {code, quantity}, imageType, side, isWithoutTryOn, patient {id, firstname, lastname, phone, birthdate, email, address}, radioType, meetings {id, startAt, finishAt, type, status, isActive, isOutside, operator {id, name}, meetingList {id, place {id, name}, doctors {id, name}, operators {id, name}}}, isReadyFitting, hasToBeSeen, isReadyDelivery, isAq, createdAt'],
    DeviceCode: ['id, alphanumericCode, numericCode, nomenclature'],
  },

};

export default (introspectionResults, resource, raFetchType) => {
  let fetchType = raFetchType;
  console.log(fetchType);
  if (raFetchType === 'CREATE' || raFetchType === 'EDIT' || raFetchType === 'UPDATE' || raFetchType === 'DELETE') {
    fetchType = 'GET_ONE';
  }
  if (raFetchType === 'GET_MANY') {
    fetchType = 'GET_LIST';
  }
  if (!listFields[fetchType] || !listFields[fetchType][resource]) {
    return '';
  }
  return listFields[fetchType][resource];
};
