import React from 'react';
import { UPDATE, withDataProvider } from 'react-admin';
import moment from 'moment';
import deviceByCategories from './deviceByCategories';
import 'moment/locale/fr';
import './DeviceCalendar.css';
import Refresher from './Refresher';

const LINE_HEIGHT = 20;
const COLUMN_WIDTH = '440px';

const getDeviceMeeting = ({ device, isNeedToCall }) => {
  const meetings = device.meetings.filter(meeting => (
    isNeedToCall
      ? moment(meeting.finishAt).isBefore(moment().subtract(24, 'hour'))
      : moment(meeting.finishAt).isAfter(moment().subtract(24, 'hour'))
  ));
  return meetings[isNeedToCall ? meetings.length - 1 : 0];
};

const getDevicePlace = ({ device, isNeedToCall }) => {
  const meeting = getDeviceMeeting({ device, isNeedToCall });
  return meeting && meeting.meetingList ? meeting.meetingList.place.name : 'Magasin';
};

const getDeviceDate = ({ device, isNeedToCall }) => {
  const meeting = getDeviceMeeting({ device, isNeedToCall });
  return moment(meeting.startAt);
};

const compareDevices = ({ deviceA, deviceB, isNeedToCall }) => {
  const dateA = getDeviceDate({ device: deviceA, isNeedToCall });
  const dateB = getDeviceDate({ device: deviceB, isNeedToCall });
  return dateA.unix() - dateB.unix();
};

const compareWeeks = (weekA, weekB) => {
  if (weekA === 'needToCall' || weekB === 'needToCall') {
    return weekA === 'needToCall' ? -1 : 1;
  }
  return moment(weekA).unix() - moment(weekB).unix();
};

const getParams = () => {
  const params = {};
  const url = window.location.href;
  const paramsString = url.split('?')[1];
  if (paramsString) {
    paramsString.split('&').forEach((param) => {
      const [key, value] = param.split('=');
      params[key] = value;
    });
  }
  return params;
};

const TitleLine = ({ title, backgroundColor }) => (
  <div style={{
    width: COLUMN_WIDTH,
    backgroundColor,
    height: `calc(1.5 * ${LINE_HEIGHT}px)`,
    paddingLeft: '5px',
    paddingRight: '5px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.2rem',
    boxSizing: 'border-box',
  }}
  >
    <span>{title}</span>
  </div>
);

const DeviceLine = ({
  lastName,
  firstName,
  type,
  place,
  isWithoutTryOn,
  date,
  onClick,
  onClickAq,
  isReady,
  isAq,
  hasToBeSeen,
}) => {
  const cellStyle = {
    fontSize: '0.90rem',
    fontWeight: 'bold',
    textAlign: 'center',
  };

  return (
    <div
      className={`device-calendar ${hasToBeSeen ? 'blink' : ''}`}
      style={{
        backgroundColor: isReady ? 'lightgrey' : '#AECCFD',
        width: COLUMN_WIDTH,
        height: LINE_HEIGHT,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        opacity: isReady ? '0.3' : 1,
        paddingRight: '5px',
        boxSizing: 'border-box',
      }}
    >
      <button
        type="button"
        style={{
          ...cellStyle,
          height: '100%',
          paddingLeft: '5px',
          textAlign: 'left',
          fontSize: '0.70rem',
          width: '6%',
          boxSizing: 'border-box',
        }}
        className="button"
        onClick={onClickAq}
      >
        {isAq ? 'AQ' : '---'}
      </button>
      <button
        type="button"
        style={{
          width: '94%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        className="button"
        onClick={onClick}
      >
        <div style={{
          ...cellStyle, fontSize: '0.80rem', textAlign: 'left', width: '43%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
        }}
        >
          {lastName.toUpperCase()}
          {' '}
          {firstName}
        </div>
        <div style={{
          ...cellStyle, fontSize: '0.75rem', width: '25%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
        }}
        >
          {type}
        </div>
        <div style={{
          ...cellStyle, fontSize: '0.70rem', width: '18%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
        }}
        >
          {place}
        </div>
        <div style={{ ...cellStyle, width: '7%', fontSize: '0.55rem' }}>{isWithoutTryOn}</div>
        <div style={{
          ...cellStyle, textAlign: 'right', width: '7%', fontSize: '0.70rem',
        }}
        >
          {date}
        </div>
      </button>
    </div>
  );
};

const DevicesCalendar = ({
  ids,
  data,
  filterValues,
  dataProvider,
  height,
}) => {
  const calendarType = filterValues.calendar;
  const isReadyKey = calendarType === 'fitting' ? 'isReadyFitting' : 'isReadyDelivery';
  const devices = deviceByCategories(
    { devices: ids.map(id => data[id]), calendarType },
  );
  const deviceKeys = Object.keys(devices);
  if (!getParams().filter) {
    window.location.href = `${window.location.href}?filter={"calendar":"fitting"}&order=DESC&page=1&perPage=150&sort=id`;
  }

  const onClick = (device) => {
    const updatedData = {
      id: device.id,
      [isReadyKey]: !device[isReadyKey],
      hasToBeSeen: device.hasToBeSeen ? false : undefined,
    };
    if (device.hasToBeSeen) {
      delete updatedData[isReadyKey];
    }
    dataProvider(UPDATE, 'Device', { id: device.id, data: updatedData }, {
      onSuccess: {
        notification: { body: 'Statut mis à jour avec succès', level: 'info' },
      },
      onFailure: {
        notification: { body: 'Erreur: l\'opération a échoué', level: 'warning' },
      },
    });
  };
  const onClickAq = (device) => {
    const updatedData = {
      id: device.id,
      isAq: !device.isAq,
    };
    dataProvider(UPDATE, 'Device', { id: device.id, data: updatedData }, {
      onSuccess: {
        notification: { body: 'AQ mis à jour avec succès', level: 'info' },
      },
      onFailure: {
        notification: { body: 'Erreur: l\'opération a échoué', level: 'warning' },
      },
    });
  };
  const devicesDivs = deviceKeys.sort(compareWeeks).map(key => (
    <React.Fragment key={key}>
      <TitleLine backgroundColor={key === 'needToCall' ? '#E7A381' : 'grey'} title={key === 'needToCall' ? 'A convoquer' : `Semaine du ${moment(key).format('ll')} au ${moment(key).add(5, 'day').format('ll')}`} />
      {devices[key].sort((deviceA, deviceB) => compareDevices({ deviceA, deviceB, isNeedToCall: key === 'needToCall' })).map(device => (
        <DeviceLine
          key={device.id}
          isReady={device[isReadyKey]}
          isAq={device.isAq}
          hasToBeSeen={device.hasToBeSeen}
          lastName={device.patient.lastname}
          firstName={device.patient.firstname}
          type={device.type}
          place={getDevicePlace({ device, isNeedToCall: key === 'needToCall' })}
          isWithoutTryOn={device.isWithoutTryOn ? 'Direct' : ''}
          date={getDeviceDate({ device, isNeedToCall: key === 'needToCall' }).format('D/M')}
          onClick={() => onClick(device)}
          onClickAq={() => onClickAq(device)}
        />
      ))}
    </React.Fragment>
  ));
  return (
    <>
      <Refresher />
      <div style={{
        height,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        gap: '3px 20px',
      }}
      >
        {devicesDivs}
      </div>
    </>
  );
};

export default withDataProvider(DevicesCalendar);
