import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification, UPDATE } from 'react-admin';
import { push } from 'react-router-redux';
import dataProviderSingleton from '../dataProvider';


class GenerateWeekButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const {
      showNotification: reduxShowNotification, filterValues,
    } = this.props;
    dataProviderSingleton.get().then(dataProvider => dataProvider(UPDATE, 'generateWeek', { startAt: filterValues.from })
      .then(() => {
        reduxShowNotification('Semaine généré avec succès');
        window.location.reload();
      })
      .catch((e) => {
        console.error(e);
        reduxShowNotification('Erreur: la génération a échoué', 'warning');
      }));
    // const updatedRecord = { operationName: 'generateWeek', variables: {}, query: `mutation generateWeek{generateWeek(startAt: "${filterValues.from}"){errors}}` };
    // fetch(process.env.REACT_APP_API_URL, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(updatedRecord),
    // })
    //   .then(() => {
    //     reduxShowNotification('Semaine généré avec succès');
    //     reduxPush('/CalendarDay');
    //   })
    //   .catch((e) => {
    //     reduxShowNotification('Erreur: la génération a échoué', 'warning');
    //   });
  }

  render() {
    return <Button variant="contained" color="primary" onClick={this.handleClick}>Génération automatique</Button>;
  }
}

GenerateWeekButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(null, {
  showNotification,
  push,
})(GenerateWeekButton);
