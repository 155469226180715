import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change as reduxChange, submit as reduxSubmit, isSubmitting as reduxIsSubmitting } from 'redux-form';
import {
  fetchEnd as raFetchEnd,
  fetchStart as raFetchStart,
  required,
  showNotification as raShowNotification,
  Button,
  SaveButton,
  SimpleForm,
  TextInput,
  crudGetMatching as raCrudGetMatching,
  CREATE,
  REDUX_FORM_NAME,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import DataProvider from '../dataProvider';

class DoctorQuickCreateButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      showDialog: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClick() {
    this.setState({ showDialog: true });
  }

  handleCloseClick() {
    this.setState({ showDialog: false });
  }

  handleSaveClick() {
    const { submit } = this.props;

    submit('doctor-quick-create');
  }

  handleSubmit(values) {
    const {
      change, fetchStart, fetchEnd, showNotification, crudGetMatching,
    } = this.props;

    fetchStart();

    DataProvider.get().then(dataProvider => dataProvider(CREATE, 'Doctor', { data: { name: values.name } })
      .then(({ data }) => {
        change(REDUX_FORM_NAME, 'doctor.id', data.id);
        crudGetMatching(
          'Doctor',
          'Device@doctor.id',
          { page: 1, perPage: 5 },
          { field: 'id', order: 'DESC' },
          {},
        );
        setTimeout(() => {
          change(REDUX_FORM_NAME, 'doctor.id', data.id);
        }, 1000);
        setTimeout(() => {
          change(REDUX_FORM_NAME, 'doctor.id', data.id);
        }, 2000);
        this.setState({ showDialog: false });
      })
      .catch((error) => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        fetchEnd();
      }));
  }

  render() {
    const { showDialog } = this.state;
    const { isSubmitting } = this.props;

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="ra.action.create">
          <IconContentAdd />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Création docteur"
        >
          <DialogTitle>Création docteur</DialogTitle>
          <DialogContent>
            <SimpleForm
              form="doctor-quick-create"
              resource="Doctor"
              onSubmit={this.handleSubmit}
              toolbar={null}
            >
              <TextInput label="Nom" source="name" validate={required()} />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: reduxIsSubmitting('doctor-quick-create')(state),
});

const mapDispatchToProps = {
  change: reduxChange,
  fetchEnd: raFetchEnd,
  fetchStart: raFetchStart,
  showNotification: raShowNotification,
  submit: reduxSubmit,
  crudGetMatching: raCrudGetMatching,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DoctorQuickCreateButton);
