import { Mutation } from 'react-admin';
import Button from '@material-ui/core/Button';
import React from 'react';

const options = {
  undoable: true,
  onSuccess: {
    notification: { body: 'Status du rdv mis à jour', level: 'info' },
    refresh: true,
  },
  onError: {
    notification: { body: 'Erreur: Le status n\'a pas pû être mis à jour', level: 'warning' },
  },
};

const SelectStatusInput = ({ id, status, label }) => {
  const payload = { id, data: { id, status } };
  return (
    <Mutation
      type="UPDATE"
      resource="Meeting"
      payload={payload}
      options={options}
    >
      {approve => (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={(event) => { event.preventDefault(); approve(); }}
          style={{
            width: '100%', marginTop: '1.3rem', marginLeft: '0.2rem', marginRight: '0.2rem',
          }}
        >
          {label}
        </Button>
      )}
    </Mutation>
  );
};

export default SelectStatusInput;
