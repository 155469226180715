import React from 'react';
import './DevicePdfExport.css';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { ReferenceField } from 'react-admin';
import Button from '@material-ui/core/Button';
import { GetApp } from '@material-ui/icons';
import { DeviceRadioTypes } from './constants';
import headImage from './images/head.png';
import trunkImage from './images/trunk.png';
import legImage from './images/leg.png';
import corsetSiege from './images/corset-siege.png';
import verticalisateur from './images/verticalisateur.png';


const EXPORTED_DIV_ID = 'pdf_to_export';
let pdfTitle;

const exportPdf = () => {
  const element = document.getElementById(EXPORTED_DIV_ID);
  const opt = {
    filename: pdfTitle,
    html2canvas: { scale: 4 },
    jsPDF: { format: 'a4', orientation: 'landscape' },
  };
  html2pdf().set(opt).from(element).save();
};
const BoldTextField = ({ source, record = {} }) => <b>{record && record[source]}</b>;

const getPlace = (meeting) => {
  if (!meeting) {
    return null;
  }
  if (meeting.meetingList !== null) {
    return meeting.meetingList.place.name;
  }
  return meeting.isOutside ? 'Extérieur' : 'Magasin';
};

function DevicePdfExport({ record, ...rest }) {
  const device = {
    ...record,
    meetings: record.meetings.filter(({ isActive }) => isActive) || [],
    patient: record.patient || {},
    operator: record.operator || {},
    doctor: record.doctor || {},
  };
  const object = {
    TETE: headImage,
    TRONC: trunkImage,
    JAMBE: legImage,
    'CORSET SIEGE': corsetSiege,
    VERTICALISATEUR: verticalisateur,
  };
  pdfTitle = (`cfao_${device.patient.lastname.toLowerCase()}_${device.patient.firstname.toLowerCase()}_${device.type.toLowerCase()}`);


  let scanMeeting = null;
  let tryOnMeeting = null;
  let deliveryMeeting = null;

  if (device.meetings[0]) {
    scanMeeting = device.meetings.find(element => element.type === 'prise_de_mesure');
    tryOnMeeting = device.meetings.find(element => element.type === 'essayage' || element.type === 'essayage_rx');
    deliveryMeeting = device.meetings.find(element => element.type === 'livraison');
  }

  let scanDate = null;
  let tryOnDate = null;
  let deliveryDate = null;

  if (scanMeeting) {
    scanDate = moment(scanMeeting.startAt).format('DD/MM/YYYY');
  }
  if (tryOnMeeting) {
    tryOnDate = moment(tryOnMeeting.startAt).format('DD/MM/YYYY');
  }
  if (deliveryMeeting) {
    deliveryDate = moment(deliveryMeeting.startAt).format('DD/MM/YYYY');
    if (!tryOnMeeting) {
      tryOnDate = 'Direct';
    }
  }

  const imageToUse = object[device.imageType];

  const dateToFormat = device.patient.birthdate;
  const formatedBirthdate = moment(dateToFormat).format('DD/MM/YYYY');

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={exportPdf}
        style={{ marginTop: 24, marginBottom: 24 }}
      >
        <GetApp />
        EXPORTER
      </Button>
      <div className="container" id={EXPORTED_DIV_ID}>
        <div className="header">
          <div className="line">
            <h1>BETEILLE ORTHOPEDIE</h1>
            <h1>FICHE INFORMATIQUE CFAO</h1>
            <p className="border-bold">VERSION 1.5 02/2020</p>
          </div>
          <div className="line">
            <p className="label">N° APPAREIL :</p>
            <p className="value" />
            <p className="label">CODE INFORMATIQUE :</p>
            <p className="value" />
          </div>
          <div className="line">
            <p className="label">NOM :</p>
            <p className="value-lastname">{device.patient.lastname}</p>
            <p className="label">Prénom :</p>
            <p className="value">{device.patient.firstname}</p>
            <p className="label">Date de Naissance :</p>
            <p className="value">{formatedBirthdate}</p>
          </div>
          <div className="line">
            <p className="label-address">Adresse :</p>
            <p className="value-address">{device.patient.address}</p>
          </div>
          <div className="line">
            <p className="label">Email :</p>
            <p className="value-email">{device.patient.email}</p>
            <p className="label">Numéro de téléphone :</p>
            <p className="value-phone">{device.patient.phone}</p>
            <p className="label">Type de radio :</p>
            <p className="value">{DeviceRadioTypes.find(({ id: radioTypeId }) => radioTypeId === device.radioType).name}</p>
          </div>
        </div>

        <div className="block1">
          <div className="cl" style={{ width: '149mm' }}>
            <div className="line" style={{ borderBottom: '1px solid' }}>
              <p className="label"> Type d&apos;appareil :</p>
              <p className="value" style={{ textAlign: 'center' }}>{device.type}</p>
            </div>
            <div className="code-container">
              {device.codes && device.codes.map(({ code, quantity }) => (
                <div style={{
                  textAlign: 'center', width: '50%', padding: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
                }}
                >
                  <p style={{ width: '30%' }}>
                    <ReferenceField source="code" reference="DeviceCode" record={{ code }} {...rest} linkType={false} allowEmpty>
                      <BoldTextField source="alphanumericCode" />
                    </ReferenceField>
                  </p>
                  <p style={{ width: '30%' }}>
                    <ReferenceField source="code" reference="DeviceCode" record={{ code }} {...rest} linkType={false} allowEmpty>
                      <BoldTextField source="numericCode" />
                    </ReferenceField>
                  </p>
                  <p style={{ width: '10%' }}>{`${quantity || '-'}`}</p>
                </div>
              ))}
            </div>
          </div>
          <div>
            <table className="imagetable">
              <tr>
                <td style={{
                  height: '7mm', display: 'flex', alignItems: 'center', justifyContent: 'center',
                }}
                >
                  <b> Sélection image:</b>
                  {' '}
                  {device.imageType}
                </td>
              </tr>
              <tr>
                <td className="imgt-disn" colSpan="4"><img width="500" src={imageToUse} alt="corps" /></td>
              </tr>
              <tr style={{
                borderTop: '1px solid', borderBottom: '1px solid', height: '7mm', display: 'flex', alignItems: 'center', justifyContent: 'center',
              }}
              >
                <p>Corrections</p>
              </tr>
              <tr style={{ height: '39.2mm' }}>
                <div style={{
                  whiteSpace: 'pre', fontSize: '12px', padding: '5px', fontWeight: 'bold',
                }}
                >
                  {device.correction}
                </div>
              </tr>
            </table>
            <table className="visa2table">
              <tr>
                <td className="imgt-fnvb1">Visa opérateur contrôle final</td>
                <td className="imgt-fnvb-bis" />
                <td className="imgt-fnvb2">Visa garnissage</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="block2">
          <table className="cl">
            <tr>
              <td className="cl-phtj">
                <span>Couleurs plastique :</span>
                <span>1er choix :</span>
              </td>
              <th className="cl-phti3" colSpan="2">{device.firstColor}</th>
            </tr>
            <tr>
              <td className="cl-phto">2ème choix :</td>
              <th className="cl-phti" colSpan="2">{device.secondColor}</th>
            </tr>
            <tr>
              <td className="cl-phti4" colSpan="2">
                Couleur mousse :
                {' '}
                <b>{device.foamColor}</b>
              </td>
              <td className="cl-phtx" rowSpan="2">
                Côté :
                {' '}
                <b>{device.side}</b>
                {' '}
              </td>
            </tr>
            <tr>
              <td className="cl-phti2" colSpan="2">
                Epaisseur :
                {' '}
                (Face)
                {' '}
                <b>
                  {device.frontThickness}
                  {' '}
                  mm
                </b>
                {' '}
                (Dos)
                {' '}
                <b>
                  {device.backThickness}
                  {' '}
                  mm
                </b>
                {' '}
              </td>
            </tr>
          </table>
        </div>
        <div className="block3">
          <table className="cl2">
            <tr>
              <td className="cm-phti">
                Applicateur :
                {' '}
                <ReferenceField source="operator.id" reference="User" record={record} {...rest} linkType={false} allowEmpty>
                  <BoldTextField source="name" />
                </ReferenceField>
              </td>
              <td />
              <td className="cm-phti">
                Prescripteur :
                {' '}
                <ReferenceField source="doctor.id" reference="Doctor" record={record} {...rest} linkType={false} allowEmpty>
                  <BoldTextField source="name" />
                </ReferenceField>
              </td>
            </tr>
            <tr>
              <td className="cm-phti">
                Date du scan :
                {' '}
                <b>
                  {scanDate}
                </b>
              </td>
              <td />
              <td className="cm-phti">
                Lieu :
                {' '}
                <b>
                  {getPlace(scanMeeting)}
                </b>
              </td>
            </tr>
            <tr>
              <td className="cm-phti">
                Date d&apos;essayage :
                {' '}
                <b>{tryOnDate}</b>
              </td>
              <td />
              <td className="cm-phti">
                Lieu :
                {' '}
                <b>{getPlace(tryOnMeeting)}</b>
              </td>
            </tr>
            <tr>
              <td className="cm-phti">
                Date de livraison :
                {' '}
                <b>{deliveryDate}</b>
              </td>
              <td />
              <td className="cm-phti">
                Lieu :
                {' '}
                <b>{getPlace(deliveryMeeting)}</b>
              </td>
            </tr>
            <tr>
              <td className="cl-phtl1">
                Visa applicateur
                <div className="visa" />
              </td>
              <td className="cl-phtl-bis" />
              <td className="cl-phtl2">Visa opérateur avant essayage</td>
            </tr>
          </table>
        </div>
        <div className="content">
          <div className="content-column" />
        </div>
      </div>
    </div>

  );
}

DevicePdfExport.defaultProps = {
  record: {},
};

export default DevicePdfExport;
