import Moment from 'moment';

export const getFilterUrl = (from, to = null) => {
  const isSameDay = to === null || new Moment(from).isSame(to, 'day');
  const editedFrom = new Moment(from).startOf('day').format();
  const editedTo = !isSameDay ? new Moment(to).startOf('day').format() : new Moment(from).endOf('day').format();
  const filterUrl = `?filter={"from":"${encodeURIComponent(editedFrom)}","to":"${encodeURIComponent(editedTo)}"}&order=DESC&page=1&perPage=100&sort=id`;
  return filterUrl;
};

export const justForNoDefaultExport = () => null;
