import React from 'react';
import {
  CardContent,
} from '@material-ui/core';
import Moment from 'moment';

function getMeetingListDivSizes(hoursHeight, firstHour, lastHour, startAt, finishAt) {
  const date = new Moment(startAt);
  const finishDate = new Moment(finishAt);
  const minuteHeight = (hoursHeight - 16) / ((lastHour - firstHour) * 60);
  const dateMinutes = (date.hours() - firstHour) * 60 + date.minutes();
  const meetingMinutes = Moment.duration(finishDate.diff(date)).as('minutes');
  return {
    top: `${dateMinutes * minuteHeight}px`,
    height: `${meetingMinutes * minuteHeight}px`,
  };
}

const CalendarUnavailability = (props) => {
  const {
    unavailability, dayInfos: {
      height, firstHour, lastHour, date,
    },
  } = props;
  const dayStartAt = Moment(date).hour(firstHour).minute(0);
  const dayFinishAt = Moment(date).hour(lastHour).minute(0);
  let unavailabilityStartAt = Moment(unavailability.startAt);
  let unavailabilityFinishAt = Moment(unavailability.finishAt);
  if (Moment(unavailability.startAt).isSameOrBefore(dayStartAt)) {
    unavailabilityStartAt = dayStartAt;
  }
  if (Moment(unavailability.finishAt).isSameOrAfter(dayFinishAt)) {
    unavailabilityFinishAt = dayFinishAt;
  }
  return (
    <div
      style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', textDecoration: 'none', background: '#F9B907', width: '100%', position: 'absolute', ...getMeetingListDivSizes(height, firstHour, lastHour, unavailabilityStartAt, unavailabilityFinishAt),
      }}
    >
      <CardContent style={{ padding: '1rem' }}>
        <p style={{ margin: 0, fontSize: '1.1rem' }}>{unavailability.title}</p>
      </CardContent>
    </div>
  );
};

export default CalendarUnavailability;
