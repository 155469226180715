import React from 'react';
import { connect } from 'react-redux';
import {
  MenuItemLink, getResources, Responsive, DashboardMenuItem,
} from 'react-admin';
import { withRouter } from 'react-router-dom';
import DefaultIcon from '@material-ui/icons/ViewList';
import { DeliveryMeetingsCalendar, FittingMeetingsCalendar } from './custom-menu-links';

const EXCLUDED_RESOURCES = ['Doctor', 'Device', 'DeviceColor'];

const MyMenu = ({
  resources, onMenuClick, logout, hasDashboard, dense,
}) => {
  resources = resources.filter(resource => resource.hasList && !EXCLUDED_RESOURCES.includes(resource.name));
  return (
    <div>
      {hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
      {resources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={resource.options.label || resource.name}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={
            resource.icon ? <resource.icon /> : <DefaultIcon />
        }
        />
      ))}
      <FittingMeetingsCalendar onClick={onMenuClick} dense={dense} />
      <DeliveryMeetingsCalendar onClick={onMenuClick} dense={dense} />
      <Responsive
        small={logout}
        medium={null}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(MyMenu));
