import React from 'react';
import {
  Edit, SimpleForm, TextInput, required, Show, SimpleShowLayout, TextField, DateField,
} from 'react-admin';
import DateTimeInput from '../utils/DateTimeInput';

export const UserUnavailabilityShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="Titre" source="title" />
      <DateField label="Début" source="startAt" showTime />
      <DateField label="Fin" source="finishAt" showTime />
    </SimpleShowLayout>
  </Show>
);

export const UserUnavailabilityEdit = props => (
  <Edit {...props} title="Édition d'indisponibilité">
    <SimpleForm redirect="show">
      <TextInput label="Titre" source="title" placeholder="Congés" validate={required()} />
      <DateTimeInput label="Début d'indisponibilité" source="startAt" validate={required()} />
      <DateTimeInput label="Fin d'indisponibilité" source="finishAt" validate={required()} />
    </SimpleForm>
  </Edit>
);
