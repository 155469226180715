import React, { Fragment } from 'react';
import Moment from 'moment';
import {
  TextField, FunctionField, ReferenceField, DateField,
} from 'react-admin';
import QuickShowModal from '../utils/QuickShowModal';
import { MeetingTypes, MeetingStatus, getName } from '../constants';
import SelectStatusInput from './SelectStatusInput';

const StatusEditButtons = ({ record, modalRecordId, ...rest }) => (
  <Fragment>
    <div style={{ display: 'flex' }}>
      { record.status !== 'NotSeen' && <SelectStatusInput {...rest} id={modalRecordId} status="NotSeen" label="À voir" /> }
      { record.status !== 'Seen' && <SelectStatusInput {...rest} id={modalRecordId} status="Seen" label="Vu" /> }
      { record.status !== 'Excused' && <SelectStatusInput {...rest} id={modalRecordId} status="Excused" label="Absent Excusé" /> }
      { record.status !== 'NotExcused' && <SelectStatusInput {...rest} id={modalRecordId} status="NotExcused" label="Absent Non Excusé" /> }
    </div>
  </Fragment>
);

export default ({
  isModalOpen, setIsModalOpen, modalRecordId, modalLabel,
}) => (
  <QuickShowModal open={isModalOpen} onToggle={setIsModalOpen} resource="Meeting" id={modalRecordId} label={modalLabel} basePath="/Meeting">
    <ReferenceField label="Patient" source="patient.id" reference="Patient" linkType="show">
      <FunctionField render={record => `${record.lastname} ${record.firstname}`} />
    </ReferenceField>
    <ReferenceField label="Rendez-vous" source="id" reference="Meeting" linkType="show">
      <FunctionField render={record => 'Voir'} />
    </ReferenceField>
    <DateField label="Date" source="startAt" />
    <FunctionField label="Date et Horaires" render={record => `Le ${Moment(record.startAt).format('LL')} de ${Moment(record.startAt).format('LT')} à ${Moment(record.finishAt).format('LT')}`} />
    <TextField label="Téléphone patient" source="patient.phone" />
    <FunctionField label="Type" render={record => getName(MeetingTypes, record.type)} />
    <TextField source="comment" label="Commentaire" />
    <FunctionField label="Statut" render={record => getName(MeetingStatus, record.status)} />
    <h4>Changer le statut :</h4>
    <StatusEditButtons modalRecordId={modalRecordId} />
  </QuickShowModal>
);
