import React from 'react';
import { addField } from 'react-admin';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

const DateTimeInput = ({
  label, required, input, meta: { touched, error },
}) => (
  <KeyboardDateTimePicker
    variant="inline"
    onChange={(date, value) => { console.log('date ', date, 'value ', value); input.onChange(date); }}
    value={input.value}
    ampm={false}
    label={label}
    errorText={touched && error}
    onError={console.log}
    format="DD/MM/YYYY HH:mm"
    required={required || false}
  />
);

export default addField(DateTimeInput);
