import React from 'react';
import {
  List, Datagrid, TabbedShowLayout, EmailField, TextField, Show, ReferenceArrayField, DateField, CardActions, Tab,
} from 'react-admin';
import UserUnavailabilityQuickCreateButton from './UserUnavailabilityQuickCreateButton';

export const UserList = props => (
  <List {...props} title="Opérateurs" bulkActionButtons={false} filterDefaultValues={{ type: 'Operator', activated: false }}>
    <Datagrid rowClick="show">
      <TextField source="name" label="Nom" />
      <EmailField source="email" />
    </Datagrid>
  </List>
);

const UserShowActions = ({ data, ...props }) => (
  <CardActions>
    <UserUnavailabilityQuickCreateButton record={data} {...props} />
  </CardActions>
);

const UnavailabitiesField = ({ source, record = {}, ...props }) => {
  record.unavailabilityIds = record.unavailabilities ? record.unavailabilities.map(e => e.id) : [];
  return (
    <ReferenceArrayField {...props} label="Indisponibilités" reference="UserUnavailability" source="unavailabilityIds" record={record}>
      <Datagrid rowClick="edit">
        <TextField label="Titre" source="title" />
        <DateField label="Début" source="startAt" showTime />
        <DateField label="Fin" source="finishAt" showTime />
      </Datagrid>
    </ReferenceArrayField>
  );
};

export const UserShow = props => (
  <Show {...props} actions={<UserShowActions />}>
    <TabbedShowLayout>
      <Tab label="informations">
        <TextField source="name" />
        <EmailField source="email" />
      </Tab>
      <Tab label="Indisponibilités">
        <UnavailabitiesField />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
