import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import buildGraphQLProvider from 'ra-data-graphql';
import { InMemoryCache } from 'apollo-cache-inmemory';
import buildQuery from './buildQuery';
import { __schema as schema } from './schema.json';

function buildDataProvider() {
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_URL, credentials: 'include' });

  const logoutLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (graphQLErrors[0].message === 'Veuillez vous connecter') {
        localStorage.removeItem('logged');
        window.location.href = '/#/login';
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
    // console.log(error);
    // if (error.grapqhQLErrors && error.grapqhQLErrors.length > 0) {
    //   console.log(error.grapqhQLErrors[0].message);
    // }
  });
  return buildGraphQLProvider({
    introspection: { schema },
    clientOptions: {
      link: logoutLink.concat(httpLink),
      cache: new InMemoryCache({
        addTypename: false,
      }),
    },
    buildQuery,
  });
}

class DataProvider {
  constructor() {
    this.singletonData = {};
  }

  get() {
    const self = this;
    if (this.singletonData.dataProvider) {
      return Promise.resolve(this.singletonData.dataProvider);
    }
    return buildDataProvider()
      .then((buildedDataProvider) => { self.singletonData.dataProvider = buildedDataProvider; return self.singletonData.dataProvider; });
  }
}

const instance = new DataProvider();
Object.freeze(instance);

export default instance;
