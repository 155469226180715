import React, { Fragment } from 'react';
import {
  Query, SimpleShowLayout,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const QuickShowModal = (props) => {
  const onClose = (event) => {
    event.preventDefault();
    props.onToggle(false);
  };
  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{props.label}</DialogTitle>
        <DialogContent>
          <Query type="GET_ONE" resource={props.resource} payload={{ id: props.id }}>
            {({ data, loading, error }) => {
              if (loading) { return <CircularProgress />; }
              if (error) { return <p>ERROR</p>; }
              return (
                <SimpleShowLayout record={data} resource={props.resource} basePath={props.basePath || props.resource}>
                  {props.children}
                </SimpleShowLayout>
              );
            }}
          </Query>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
              RETOUR
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default QuickShowModal;
