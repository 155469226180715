import React from 'react';
import { MenuItemLink } from 'react-admin';
import { LocalShipping, Accessibility } from '@material-ui/icons';

export const DeliveryMeetingsCalendar = ({ dense, onClick }) => (
  <MenuItemLink
    key="delivery"
    to={'/Device?filter={"calendar":"delivery"}&order=DESC&page=1&perPage=150&sort=id'}
    primaryText="Livraison"
    onClick={onClick}
    dense={dense}
    leftIcon={<LocalShipping />}
  />
);

export const FittingMeetingsCalendar = ({ dense, onClick }) => (
  <MenuItemLink
    key="fitting"
    to={'/Device?filter={"calendar":"fitting"}&order=DESC&page=1&perPage=150&sort=id'}
    primaryText="Essayage"
    onClick={onClick}
    dense={dense}
    leftIcon={<Accessibility />}
  />
);
