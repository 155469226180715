import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Field, blur,
} from 'redux-form';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import Moment from 'moment';

class DateTwoTimeInput extends Component {
  static buildDates(date, startTime, finishTime) {
    return {
      startDateTime: date.minute(startTime.minute()).hour(startTime.hour()).format(),
      finishDateTime: date.minute(finishTime.minute()).hour(finishTime.hour()).format(),
    };
  }

  constructor(props) {
    super(props);
    let date = new Moment();
    let finishAt = new Moment();
    if (props.startDate || props.record.startAt) {
      date = props.startDate ? new Moment(props.startDate) : new Moment(props.record.startAt);
    }
    if (props.finishDate || props.record.finishAt) {
      finishAt = props.finishDate ? new Moment(props.finishDate) : new Moment(props.record.finishAt);
    }
    const startTime = new Moment(date);
    const finishTime = new Moment(finishAt);
    this.state = {
      date,
      startTime,
      finishTime,
      onlyTime: props.onlyTime ? props.onlyTime : false,
    };
  }

  componentDidMount() {
    this.handleChange = this.handleChange.bind(this);
    this.handleChange('date', this.state.date);
  }

  handleChange(key, value) {
    const payload = {};
    payload[key] = value;
    this.setState(payload, () => {
      const { date, startTime, finishTime } = this.state;
      const dates = DateTwoTimeInput.buildDates(date, startTime, finishTime);
      this.props.dispatch(blur('record-form', 'startAt', dates.startDateTime));
      this.props.dispatch(blur('record-form', 'finishAt', dates.finishDateTime));
    });
  }

  render() {
    const {
      date, startTime, finishTime, onlyTime,
    } = this.state;
    return (
      <span>
        {!onlyTime && <KeyboardDatePicker format="DD/MM/YYYY" label="Jour" value={date} onChange={value => this.handleChange('date', value)} margin="normal" />}
        <KeyboardTimePicker label="Début" mask="__:__" value={startTime} minutesStep={5} ampm={false} margin="normal" onChange={value => this.handleChange('startTime', value)} />
        <KeyboardTimePicker label="Fin" mask="__:__" value={finishTime} minutesStep={5} ampm={false} margin="normal" onChange={value => this.handleChange('finishTime', value)} />
        <Field name="startAt" component="input" type="text" style={{ display: 'none' }} />
            &nbsp;
        <Field id="finishAt" name="finishAt" component="input" type="text" style={{ display: 'none' }} />
      </span>
    );
  }
}

export default connect()(DateTwoTimeInput);
