import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import 'moment/locale/fr';
import {
  List, Filter, CardActions,
} from 'react-admin';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import {
  ArrowForward, ArrowBack, ZoomOut, ZoomIn,
} from '@material-ui/icons';
import { isMobile } from 'react-device-detect';
import Calendar from './Calendar';
import DataProvider from '../dataProvider';
import GenerateWeekButton from './GenerateWeekButton';
import WeekButtonFilter from '../utils/WeekButtonFilter';

const MeetingListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <CardActions>
    {total < 12 && !new Moment(filterValues.from).isSame(filterValues.to, 'day') && <GenerateWeekButton filterValues={filterValues} />}
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/MeetingList/create',
        state: {
          record: { startAt: new Moment(filterValues.from).hour(9).format(), finishAt: new Moment(filterValues.from).hour(10).format() },
        },
      }}
    >
      Créer une liste de RDV
    </Button>
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/Meeting/create',
        state: {
          startDate: new Moment(filterValues.from).hour(9).format(), finishDate: new Moment(filterValues.from).hour(9).minutes(15).format(),
        },
      }}
    >
      Créer un RDV
    </Button>
  </CardActions>
);

const MeetingFilter = (props) => {
  Moment.locale('fr');
  const { filterValues: { from, to } } = props;
  const isDayView = new Moment(from).isSame(to, 'day');
  const pageIncrement = isDayView ? 1 : 7;
  let previousFrom = new Moment(from).subtract(pageIncrement, 'days').format();
  let previousTo = new Moment(to).subtract(pageIncrement, 'days').format();
  if (isDayView && Moment(previousFrom).weekday() === 6) {
    previousFrom = Moment(previousFrom).subtract(1, 'day').format();
    previousTo = Moment(previousTo).subtract(1, 'day').format();
  }
  let nextFrom = new Moment(from).add(pageIncrement, 'days').format();
  let nextTo = new Moment(to).add(pageIncrement, 'days').format();
  if (isDayView && Moment(nextFrom).weekday() === 6) {
    nextFrom = Moment(nextFrom).add(1, 'day').format();
    nextTo = Moment(nextTo).add(1, 'day').format();
  }
  const previousWeek = `?filter={"from":"${encodeURIComponent(previousFrom)}","to":"${encodeURIComponent(previousTo)}"}&order=DESC&page=1&perPage=100&sort=id`;
  const nextWeek = `?filter={"from":"${encodeURIComponent(nextFrom)}","to":"${encodeURIComponent(nextTo)}"}&order=DESC&page=1&perPage=100&sort=id`;
  const switchView = `?filter={"from":"${encodeURIComponent(new Moment(from).startOf('week').format())}","to":"${encodeURIComponent(isDayView ? new Moment(from).endOf('week').format() : new Moment(from).endOf('day').format())}"}&order=DESC&page=1&perPage=100&sort=id`;
  const myProps = { ...props };
  return (
    <Filter
      {...myProps}
      style={{
        display: 'flex', alignItems: 'center', marginBottom: 'auto', marginTop: 'auto',
      }}
    >
      <Link
        alwaysOn
        to={{
          pathname: '/CalendarDay',
          search: previousWeek,
        }}
      >
        <Button alwaysOn>
          <ArrowBack />
        </Button>
      </Link>
      <Chip label={new Moment(from).format('LL')} alwaysOn />
      {!isDayView && <Chip label={new Moment(to).format('LL')} alwaysOn />}
      <Link
        alwaysOn
        to={{
          pathname: '/CalendarDay',
          search: nextWeek,
        }}
      >
        <Button alwaysOn>
          <ArrowForward />
        </Button>
      </Link>
      <WeekButtonFilter alwaysOn />
      <Link
        alwaysOn
        to={{
          pathname: '/CalendarDay',
          search: switchView,
        }}
      >
        <Button alwaysOn>
          {isDayView ? <ZoomOut /> : <ZoomIn />}
        </Button>
      </Link>
    </Filter>
  );
};

const CalendarDayList = (props) => {
  const from = new Moment().startOf('week').format();
  let to = new Moment().endOf('week').format();
  if (isMobile) {
    to = new Moment(from).endOf('day').format();
  }
  return (
    <List {...props} title="Beteille Orthopédie" actions={<MeetingListActions />} filters={<MeetingFilter />} filterDefaultValues={{ from, to }}>
      <Calendar2 key={`${from}${to}`} />
    </List>
  );
};

function getISODate(date) {
  const momentDate = new Moment(date);
  return momentDate.format('YYYY-MM-DD');
}

function getWeekObject(startDate) {
  const week = {};
  for (let index = 0; index < 6; index += 1) {
    const weekDate = new Moment(startDate).add(index, 'days');
    week[getISODate(weekDate)] = {};
  }
  return week;
}

function getDayObject(startDate) {
  const day = {};
  day[getISODate(new Moment(startDate))] = {};
  return day;
}

function fillOperators(calendar, operators) {
  const filledCalendar = calendar;
  Object.keys(filledCalendar).forEach((date) => {
    operators.forEach((operator) => {
      filledCalendar[date][operator.name] = { meetings: [], meetingLists: [], userUnavailabilities: [] };
    });
  });
  return filledCalendar;
}

class Calendar2 extends Component {
  constructor(props) {
    super(props);
    this.state = { operators: [] };
  }

  componentDidMount() {
    const self = this;
    const { filterValues: { from } } = this.props;
    DataProvider.get()
      .then(dataProvider => dataProvider('GET_LIST', 'User', { pagination: { page: 1, perPage: 100 }, sort: { field: 'id', order: 'ASC' }, filter: { type: 'Operator', date: from } }))
      .then(({ data }) => self.setState({ operators: data }));
  }

  componentDidUpdate(prevProps) {
    const self = this;
    const { filterValues: { from } = {} } = this.props;
    const { filterValues: { from: prevFrom } = {} } = prevProps || {};
    if (from !== prevFrom) {
      DataProvider.get()
        .then(dataProvider => dataProvider('GET_LIST', 'User', { pagination: { page: 1, perPage: 100 }, sort: { field: 'id', order: 'ASC' }, filter: { type: 'Operator', date: from } }))
        .then(({ data }) => self.setState({ operators: data }));
    }
  }

  render() {
    const { data, ids, filterValues: { from, to } } = this.props;
    const { operators } = this.state;
    let calendar = null;
    const isDayView = new Moment(from).isSame(to, 'day');
    if (isDayView) {
      calendar = getDayObject(from);
    } else {
      calendar = getWeekObject(from);
    }
    calendar = fillOperators(calendar, operators);
    if (operators.length > 0) {
      ids.forEach((key) => {
        const {
          meetingLists, meetings,
          userUnavailabilities,
        } = data[key];
        meetingLists.forEach((meetingList) => {
          const date = getISODate(new Date(meetingList.startAt));
          if (calendar[date] && meetingList.operators) {
            meetingList.operators.forEach((operator) => {
              if (calendar[date] && calendar[date][operator.name] && calendar[date][operator.name].meetingLists) {
                calendar[date][operator.name].meetingLists.push(meetingList);
              }
            });
          }
        });
        meetings.forEach((meeting) => {
          const date = getISODate(new Date(meeting.startAt));
          if (calendar[date] && meeting.operator.name && calendar[date][meeting.operator.name]) {
            calendar[date][meeting.operator.name].meetings.push(meeting);
          }
        });
        userUnavailabilities.forEach((unavailability) => {
          const date = getISODate(key);
          if (calendar[date] && unavailability.operator.name && calendar[date][unavailability.operator.name]) {
            calendar[date][unavailability.operator.name].userUnavailabilities.push(unavailability);
          }
        });
      });
    }

    return (<Calendar calendar={calendar} isDayView={isDayView} />);
  }
}

export default connect(null, { push })(CalendarDayList);
