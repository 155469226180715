import React from 'react';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import CalendarUserDay from './CalendarUserDay';
import { getFilterUrl } from './meetingListUtils';


const CalendarDay = ({ date, userDays, isDayView }) => (
  <Link
    style={{
      background: '#E8F1F2', textAlign: 'center', margin: '0.3rem', flexGrow: '1', flexBasis: 0,
    }}
    to={{
      pathname: '/CalendarDay',
      search: getFilterUrl(date),
    }}
  >
    <div style={{ height: '100px' }}>
      <h2 style={{ margin: 0, paddingTop: '1rem' }}>
        {new Moment(date).format('dddd')}
      </h2>
      <div>
        {new Moment(date).format('LL')}
      </div>
    </div>

    <div style={{
      height: '600px', display: 'flex', flexDirection: 'row', marginTop: '8px',
    }}
    >
      {Object.keys(userDays).map(username => <CalendarUserDay key={username} date={date} isDayView={isDayView} username={username} userUnavailabilities={userDays[username].userUnavailabilities} meetings={userDays[username].meetings} meetingLists={userDays[username].meetingLists} />)}
    </div>
  </Link>
);

export default CalendarDay;
