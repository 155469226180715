import React from 'react';
import { TextInput, required } from 'react-admin';
import moment from 'moment';
import QuickCreateButton from '../utils/QuickCreateButton';
import DateTimeInput from '../utils/DateTimeInput';

const UserUnavailabilityQuickCreateButton = props => (
  <QuickCreateButton {...props} label="Créer une indisponibilité" createdResource="UserUnavailability">
    <TextInput source="userId" defaultValue={props.record ? props.record.id : ''} style={{ display: 'none' }} />
    <TextInput label="Titre" source="title" placeholder="Congés" validate={required()} />
    <DateTimeInput label="Début d'indisponibilité" source="startAt" validate={required()} defaultValue={moment()} />
    <DateTimeInput label="Fin d'indisponibilité" source="finishAt" validate={required()} defaultValue={moment()} />
  </QuickCreateButton>
);

export default UserUnavailabilityQuickCreateButton;
