import React from 'react';
import PropTypes from 'prop-types';
import {
  List, Datagrid, SimpleForm, Create, TextInput, Edit, Filter,
} from 'react-admin';

const required = (message = 'Required') => value => (value ? undefined : message);

const DoctorNameField = ({ source, record = {} }) => (
  <span>{`${record[source].toUpperCase()}`}</span>

);
DoctorNameField.defaultProps = { label: 'Nom', addLabel: true };
DoctorNameField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const DoctorFilter = props => (
  <Filter {...props}>
    <TextInput label="Rechercher" source="q" alwaysOn />
  </Filter>
);

export const DoctorList = props => (
  <List {...props} title="Docteurs" bulkActionButtons={false} filters={<DoctorFilter />}>
    <Datagrid rowClick="edit">
      <DoctorNameField source="name" />
    </Datagrid>
  </List>
);

export const DoctorCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nom" source="name" validate={required()} />
    </SimpleForm>
  </Create>
);

const DoctorEditTitle = ({ record }) => (
  <span>
Éditer
    {' '}
    {record ? `${record.name.toUpperCase()}` : ''}
  </span>
);

export const DoctorEdit = props => (
  <Edit {...props} title={<DoctorEditTitle />}>
    <SimpleForm redirect="list">
      <TextInput label="Nom" source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
