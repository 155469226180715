import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  Create,
  SelectInput,
  TextInput,
  Edit,
  Filter,
  DateField,
  LongTextInput,
  Show,
  TabbedShowLayout,
  Tab,
  EmailField,
  ReferenceArrayField,
  FunctionField,
  EditButton,
  CreateButton,
  CardActions,
} from 'react-admin';
import { Link } from 'react-router-dom';
import voca from 'voca';
import moment from 'moment';
import MyDateInput from './MyDateInput';
import { MeetingStatus, MeetingTypes, getName } from './constants';

const required = (message = 'Champs requis') => value => (value
  ? undefined
  : message);

const PatientFilter = props => (
  <Filter {...props}>
    <TextInput label="Rechercher" source="q" alwaysOn />
  </Filter>
);

export const PatientList = props => (
  <List {...props} bulkActionButtons={false} filters={<PatientFilter />}>
    <Datagrid rowClick="show">
      <TextField label="Genre" source="gender" />
      <TextField
        label="Nom"
        source="lastname"
        style={{
          textTransform: 'uppercase',
        }}
      />
      <TextField
        label="Prénom"
        source="firstname"
        style={{
          textTransform: 'capitalize',
        }}
      />
      <DateField label="Date de naissance" source="birthdate" locales="fr-FR" />
    </Datagrid>
  </List>
);

const validatePatientCreation = (values) => {
  const errors = {};
  if (!values.birthdate || values.birthdate === 'Invalid date') {
    errors.birthdate = ['La date de naissance est requise'];
  }
  return errors;
};

export const PatientCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validatePatientCreation} redirect="show">
      <SelectInput
        label="Genre"
        source="gender"
        choices={[
          {
            id: 'M',
            name: 'M',
          }, {
            id: 'Mme',
            name: 'Mme',
          },
        ]}
        validate={required()}
      />
      <TextInput label="Nom" source="lastname" validate={required()} />
      <TextInput label="Prénom" source="firstname" validate={required()} />
      <TextInput
        label="Email"
        source="email"
        type="email"
        placeholder="benjamin.fille@comdeslezards.com"
      />
      <TextInput
        label="Téléphone"
        source="phone"
        placeholder="0766890921"
        validate={required()}
      />
      <TextInput label="Téléphone secondaire" source="secondPhone" placeholder="0494890921" />
      <MyDateInput label="Date de naissance" source="birthdate" />
      <LongTextInput source="comment" />
      <LongTextInput label="Adresse" source="address" />
    </SimpleForm>
  </Create>
);

const PatientEditTitle = ({ record }) => (
  <span>
    Éditer
    {record
      ? `${record
        .lastname
        .toUpperCase()} ${record
        .firstname}`
      : ''}
  </span>
);

export const PatientEdit = props => (
  <Edit {...props} title={<PatientEditTitle />}>
    <SimpleForm redirect="show">
      <SelectInput
        label="Genre"
        source="gender"
        choices={[
          {
            id: 'M',
            name: 'M',
          }, {
            id: 'Mme',
            name: 'Mme',
          },
        ]}
      />
      <TextInput label="Nom" source="lastname" validate={required()} />
      <TextInput label="Prénom" source="firstname" validate={required()} />
      <TextInput
        label="Email"
        source="email"
        type="email"
        placeholder="benjamin.fille@comdeslezards.com"
      />
      <TextInput label="Téléphone" source="phone" placeholder="0766890921" />
      <TextInput label="Téléphone secondaire" source="secondPhone" placeholder="0494890921" />
      <MyDateInput source="birthdate" />
      <LongTextInput source="comment" />
      <LongTextInput label="Adresse" source="address" />
    </SimpleForm>
  </Edit>
);

const PatientShowTitle = ({ record }) => (
  <span>
    {record
      ? `${record
        .gender} ${record
        .lastname
        .toUpperCase()} ${voca
        .capitalize(record.firstname)}`
      : ''}
  </span>
);

const MeetingsField = ({
  source,
  record = {},
  ...props
}) => {
  record.meetingIds = record.meetings
    ? record
      .meetings
      .map(meeting => meeting.id)
    : [];
  return (
    <ReferenceArrayField
      label="Rendez-vous"
      reference="Meeting"
      source="meetingIds"
      record={record}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField
          label="Lieu"
          source="meetingList.place.name"
          style={{
            textTransform: 'capitalize',
          }}
        />
        <DateField label="Date" source="startAt" locales="fr-FR" />
        <FunctionField label="Type" render={FieldRecord => getName(MeetingTypes, FieldRecord.type)} />
        <FunctionField label="Appareil" render={({ device }) => `${device && device.type} - ${device && moment(device.createdAt).format('DD/MM/YYYY')}`} />
        <FunctionField label="Statut" render={FieldRecord => getName(MeetingStatus, FieldRecord.status)} />
        <TextField
          label="Commentaire"
          source="comment"
        />
      </Datagrid>
    </ReferenceArrayField>

  );
};

const DevicesField = ({
  source,
  record = {},
  ...props
}) => {
  record.deviceIds = record.devices
    ? record
      .devices
      .map(device => device.id)
    : [];
  return (
    <ReferenceArrayField
      label="Appareils"
      reference="Device"
      source="deviceIds"
      record={record}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField
          label="Type d'appareil"
          source="type"
        />
        <DateField
          label="Date de création"
          source="createdAt"
        />
      </Datagrid>
    </ReferenceArrayField>

  );
};

const PatientActions = (props) => {
  const { data = {} } = props;
  return (
    <CardActions>
      <EditButton
        to={{
          pathname: `/Patient/${data.id}`,
        }}
      />
      <CreateButton
        color="primary"
        component={Link}
        to={{
          pathname: '/Device/create',
          state: {
            record: { patientId: data.id },
          },
        }}
        label="Créer un appareil"
      />
    </CardActions>
  );
};

export const PatientShow = props => (
  <Show {...props} title={<PatientShowTitle />} actions={<PatientActions />}>
    <TabbedShowLayout>
      <Tab label="informations">
        <TextField label="Nom" source="lastname" />
        <TextField label="Prénom" source="firstname" />
        <DateField label="Date de naissance" source="birthdate" locales="fr-FR" />
        <EmailField source="email" />
        <TextField label="Téléphone" source="phone" />
        <TextField label="Téléphone secondaire" source="secondPhone" />
        <TextField label="Commentaire" source="comment" />
        <TextField component="pre" label="Adresse" source="address" />
      </Tab>
      <Tab label="Rendez-vous">
        <MeetingsField />
      </Tab>
      <Tab label="Appareils">
        <DevicesField />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
