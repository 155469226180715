import React, { Fragment, useState } from 'react';
import Moment from 'moment';
import MeetingsLine from './MeetingListShowMeetingsLine';
import MeetingShowModal from '../meetings/MeetingShowModal';


function getMinutes(from, to) {
  return Moment
    .duration(new Moment(to).diff(new Moment(from)))
    .asMinutes();
}

const MeetingLineContainer = ({
  meetingList, meetings, lineHeight, schedule,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalRecordId, setModalRecordId] = useState(null);
  const [modalLabel, setModalLabel] = useState(null);
  return (
    <Fragment>
      <MeetingShowModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modalRecordId={modalRecordId} modalLabel={modalLabel} />
      <MeetingsLine id={`meetingLine${schedule.start.format()}`} key={`meetingLine${schedule.start.format()}`} meetings={meetings} lineHeight={lineHeight} schedule={schedule} meetingList={meetingList} setIsModalOpen={setIsModalOpen} setModalLabel={setModalLabel} setModalRecordId={setModalRecordId} />
    </Fragment>
  );
};

const MeetingListShowMeetings = ({
  source,
  record = {},
}) => {
  const meetings = record[source]
    ? record[source]
    : [];
  const { startAt, finishAt } = record;
  const meetingDurationInMinutes = 15;
  const minutesSizeInPixels = 2;
  const nbMinutes = getMinutes(startAt, finishAt);
  const nbMeetings = nbMinutes / meetingDurationInMinutes;
  const meetingBlocks = [];
  for (let index = 0; index < nbMeetings; index += 1) {
    const meetingStart = new Moment(startAt).add(index * meetingDurationInMinutes, 'm');
    const meetingEnd = new Moment(meetingStart).add(meetingDurationInMinutes, 'm');
    meetingBlocks.push({
      key: `meetingLine${index}`,
      schedule: {
        start: meetingStart,
        end: meetingEnd,
      },
      meetings: [],
    });
  }
  meetings.forEach((meeting) => {
    if (meeting.isActive && new Moment(startAt).isSameOrBefore(meeting.startAt) && new Moment(finishAt).isSameOrAfter(meeting.startAt)) {
      const nbMinutesFromStart = getMinutes(startAt, meeting.startAt);
      meetingBlocks[Math.floor((nbMinutesFromStart / nbMinutes * nbMeetings).toFixed(4))]
        .meetings
        .push(meeting);
    }
  });
  return (
    <div style={{
      marginTop: '2rem',
      background: '#bae1ff',
    }}
    >
      <div style={{
        display: 'flex',
      }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          {meetingBlocks.map(({ key, meetings: lineMeetings, schedule }) => (
            <MeetingLineContainer
              key={key}
              meetingList={record}
              meetings={lineMeetings}
              schedule={schedule}
              lineHeight={minutesSizeInPixels * meetingDurationInMinutes}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MeetingListShowMeetings;
