import React from 'react';
import { DropTarget } from 'react-dnd';
import { Link } from 'react-router-dom';
import Meeting from './MeetingListShowMeetingsMeeting';

export const ItemTypes = {
  MEETING: 'meeting',
};

const meetingLineTarget = {
  drop(props, monitor) {
    return props;
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

function MeetingsLine({
  connectDropTarget, isOver, setModalRecordId, setModalLabel, setIsModalOpen, meetings, lineHeight, schedule, meetingList,
}) {
  return connectDropTarget(
    <div
      style={{
        display: 'flex',
        height: `${lineHeight}px`,
        background: isOver ? '#849cb1' : '#bae1ff',
        marginBottom: '2px',
        color: 'black',

      }}
    >
      <Link
        style={{
          marginTop: 'auto',
          marginBottom: 'auto',
          width: '48px',
          background: 'white',
          paddingLeft: '3px',
          paddingRight: '3px',
          marginLeft: '3px',
          marginRight: '3px',
          height: '90%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
        to={{
          pathname: '/Meeting/create',
          state: {
            startDate: schedule
              .start
              .format(),
            finishDate: schedule
              .end
              .format(),
            record: {
              meetingListId: meetingList.id,
            },
          },
        }}
      >

        {schedule
          .start
          .format('H[h]mm')}
      </Link>
      {meetings.map(meeting => (
        <Meeting id={meeting.id} key={meeting.id} meeting={meeting} setIsModalOpen={setIsModalOpen} setModalLabel={setModalLabel} setModalRecordId={setModalRecordId} />
      ))}
    </div>,
  );
}

export default DropTarget(ItemTypes.MEETING, meetingLineTarget, collect)(MeetingsLine);
