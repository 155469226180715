export const MeetingTypes = [
  {
    id: 'prise_de_mesure',
    name: 'Prise de mesure',
  },
  {
    id: 'essayage',
    name: 'Essayage',
  },
  {
    id: 'essayage_rx',
    name: 'Essayage + Rx',
  },
  {
    id: 'retouche',
    name: 'Retouche',
  },
  {
    id: 'livraison',
    name: 'Livraison',
  },
  {
    id: 'controle',
    name: 'Contrôle',
  },
];

export const MeetingStatus = [
  {
    id: 'NotSeen',
    name: 'À voir',
  },
  {
    id: 'Seen',
    name: 'Vu',
  },
  {
    id: 'Excused',
    name: 'Excusé',
  },
  {
    id: 'NotExcused',
    name: 'Non excusé',
  },
];

export const getName = (values, id) => {
  let rtnValue;
  values.forEach((value) => {
    if (value.id === id) {
      rtnValue = value.name;
    }
  });
  return rtnValue;
};
