import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { Notification } from 'react-admin';
import LoginForm from './LoginForm';

const sanitizeRestProps = ({
  location,
  title,
  array,
  theme,
  staticContext,
  ...rest
}) => rest;

const Login = ({
  loginForm, ...rest
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#28b4b4',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }}
    {...sanitizeRestProps(rest)}
  >
    <p style={{ color: 'red', background: 'white' }}>Si vous rencontrez des difficultés à vous connecter, desactivez vos extensions anti pub comme Adblock ou Ghostery.</p>
    <Card style={{
      minWidth: '300px',
      marginTop: '6em',
      backgroundColor: 'white',
    }}
    >
      <div style={{
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        <img src="/logo-beteille.png" alt="" style={{ width: '100px', height: '100px' }} />
      </div>
      {loginForm}
    </Card>
    <a
      style={{
        margin: '1em',
        color: 'white',
        position: 'inherit',
      }}
      href="https://comdeslezards.com"
      rel="nofollow"
    >
By Com des Lézards

    </a>
    <Notification />
  </div>
);

Login.propTypes = {
  authProvider: PropTypes.func,
  input: PropTypes.object,
  meta: PropTypes.object,
  previousRoute: PropTypes.string,
  loginForm: PropTypes.element,
};

Login.defaultProps = {
  loginForm: <LoginForm />,
};

export default Login;
