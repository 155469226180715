// in App.js
import React, { Component } from 'react';
import frenchMessages from 'ra-language-french';
import {
  Assignment, People, Business, Person, Build,
} from '@material-ui/icons';
import moment from 'moment';
import 'moment/locale/fr';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import MomentUtils from '@date-io/moment';
import { Admin, Resource } from 'react-admin';
import * as Sentry from '@sentry/browser';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReactGA from 'react-ga';
import authProvider from './authProvider';
import AppLayout from './AppLayout';
import {
  MeetingListCreate, MeetingListShow, MeetingListEdit,
} from './meetingLists/meetingLists';
import { MeetingPlaceList, MeetingPlaceCreate, MeetingPlaceEdit } from './meetingPlaces';
import { default as CalendarDaysList } from './CalendarDays/CalendarDays';
import {
  MeetingCreate, MeetingShow, MeetingEdit, MeetingList,
} from './meetings';
import { UserList, UserShow } from './users/users';
import {
  PatientCreate, PatientList, PatientEdit, PatientShow,
} from './patients';
import { DoctorCreate, DoctorList, DoctorEdit } from './doctors';
import { DeviceColorCreate, DeviceColorList, DeviceColorEdit } from './deviceColors';
import {
  DeviceCreate, DeviceList, DeviceEdit, DeviceShow,
} from './devices/devices';
import { UserUnavailabilityShow, UserUnavailabilityEdit } from './users/userUnavailabilities';
import dataProviderSingleton from './dataProvider';
import LoginPage from './LoginPage';


// import { PostCreate, PostEdit, PostList } from '../components/admin/posts';

const messages = {
  fr: frenchMessages,
};

moment.locale('fr');

const i18nProvider = locale => messages[locale];

class App extends Component {
  constructor() {
    super();
    this.state = { dataProvider: null };
    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: 'https://52f4eb2b84934198a763d620d5139cf0@sentry.io/1418884',
      });
    }
  }


  componentDidMount() {
    const self = this;
    ReactGA.initialize('UA-690635-31');
    ReactGA.pageview(window.location.pathname + window.location.search);
    dataProviderSingleton.get()
      .then(dataProvider => self.setState({ dataProvider }))
      .catch(console.log);
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return <div>Loading</div>;
    }
    const deviceResource = <Resource name="Device" options={{ label: 'Appareils' }} show={DeviceShow} create={DeviceCreate} list={DeviceList} edit={DeviceEdit} icon={Build} />;
    const resources = [
      <Resource name="CalendarDay" options={{ label: 'Calendrier' }} list={CalendarDaysList} icon={Assignment} />,
      <Resource name="Meeting" options={{ label: 'Rendez-vous' }} list={MeetingList} create={MeetingCreate} show={MeetingShow} edit={MeetingEdit} />,
      <Resource name="MeetingList" edit={MeetingListEdit} show={MeetingListShow} create={MeetingListCreate} icon={Assignment} />,
      <Resource name="MeetingPlace" options={{ label: 'Lieux de consultation' }} list={MeetingPlaceList} edit={MeetingPlaceEdit} create={MeetingPlaceCreate} icon={Business} />,
      <Resource name="Patient" show={PatientShow} create={PatientCreate} list={PatientList} edit={PatientEdit} icon={People} />,
      <Resource name="Doctor" options={{ label: 'Médecins' }} create={DoctorCreate} list={DoctorList} edit={DoctorEdit} icon={Person} />,
      <Resource name="DeviceColor" options={{ label: 'Couleurs' }} create={DeviceColorCreate} list={DeviceColorList} edit={DeviceColorEdit} icon={Person} />,
      <Resource name="User" options={{ label: 'Opérateurs' }} list={UserList} show={UserShow} icon={Person} />,
      deviceResource,
      <Resource name="UserUnavailability" show={UserUnavailabilityShow} edit={UserUnavailabilityEdit} />,
      <Resource name="DeviceCode" />,
    ];
    return (
      <DragDropContextProvider backend={HTML5Backend}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="fr" libInstance={moment}>
          <Admin locale="fr" loginPage={LoginPage} i18nProvider={i18nProvider} appLayout={AppLayout} title="Beteille Orthopédie" dataProvider={dataProvider} authProvider={authProvider}>
            {userRole => (userRole !== 'Workshop' ? resources : [deviceResource])}
          </Admin>
        </MuiPickersUtilsProvider>
      </DragDropContextProvider>

    );
  }
}

export default App;
