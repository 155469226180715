export default () => {
  const url = window.location.href;
  const deliveryUrl = 'delivery';
  const fittingUrl = 'fitting';
  let title = 'Liste des appareils';

  if (url.includes(deliveryUrl)) {
    title = 'Calendrier livraison';
  }
  if (url.includes(fittingUrl)) {
    title = 'Calendrier essayage';
  }
  return title;
};
