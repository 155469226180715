import React from 'react';
import PropTypes from 'prop-types';
import {
  List, Datagrid, SimpleForm, Create, TextInput, Edit, Filter,
} from 'react-admin';

const required = (message = 'Required') => value => (value ? undefined : message);

const DeviceColorNameField = ({ source, record = {} }) => (
  <span>{`${record[source].toUpperCase()}`}</span>

);
DeviceColorNameField.defaultProps = { label: 'Nom', addLabel: true };
DeviceColorNameField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const DeviceColorFilter = props => (
  <Filter {...props}>
    <TextInput label="Rechercher" source="q" alwaysOn />
  </Filter>
);

export const DeviceColorList = props => (
  <List {...props} title="Couleurs d'appareil" bulkActionButtons={false} filters={<DeviceColorFilter />}>
    <Datagrid rowClick="edit">
      <DeviceColorNameField source="name" />
    </Datagrid>
  </List>
);

export const DeviceColorCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nom" source="name" validate={required()} />
    </SimpleForm>
  </Create>
);

const DeviceColorEditTitle = ({ record }) => (
  <span>
Éditer
    {' '}
    {record ? `${record.name.toUpperCase()}` : ''}
  </span>
);

export const DeviceColorEdit = props => (
  <Edit {...props} title={<DeviceColorEditTitle />}>
    <SimpleForm redirect="list">
      <TextInput label="Nom" source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
