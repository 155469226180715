import moment from 'moment';
import { FITTING_MEETING_TYPES, DELIVERY_MEETING_TYPES } from './constants';

const isMeetingOfCalendarType = ({ meeting, calendarType }) => {
  const meetingTypes = calendarType === 'fitting' ? FITTING_MEETING_TYPES : DELIVERY_MEETING_TYPES;
  return meetingTypes.includes(meeting.type);
};

const isActiveMeetings = (meeting, index, meetings) => {
  if (['Excused', 'NotExcused', 'Seen'].includes(meeting.status)
  && meetings[index + 1]) {
    return false;
  }
  return true;
};

const filterMeetings = ({ deviceMeetings, calendarType, isWithoutTryOn }) => deviceMeetings
  .filter(meeting => meeting.isActive)
  .filter(meeting => isMeetingOfCalendarType({ meeting, calendarType }) || (calendarType === 'fitting' && isWithoutTryOn))
  .sort((a, b) => moment(a.startAt).unix() - moment(b.startAt).unix())
  .filter(isActiveMeetings);

const isNeedToCallCategory = deviceMeetings => deviceMeetings.some((meeting) => {
  if (moment().diff(moment(meeting.finishAt), 'hour') >= 24
  && (meeting.status === 'NotSeen' || ['Excused', 'NotExcused', 'Seen'].includes(meeting.status))) {
    return true;
  }
  return false;
});

const getWeekId = (deviceMeetings) => {
  const oldestMeeting = deviceMeetings[0];
  const meetingDate = moment(oldestMeeting.startAt);
  return meetingDate.startOf('week').toISOString();
};

export default ({ devices, calendarType }) => devices.reduce((deviceByCategories, device) => {
  const newDevicesByCategories = { ...deviceByCategories };
  const deviceMeetings = filterMeetings({
    deviceMeetings: device.meetings,
    calendarType,
    isWithoutTryOn: device.isWithoutTryOn,
  });
  if (deviceMeetings.length === 0) {
    return newDevicesByCategories;
  }
  if (isNeedToCallCategory(deviceMeetings)) {
    newDevicesByCategories.needToCall.push(device);
  } else {
    const weekId = getWeekId(deviceMeetings);
    newDevicesByCategories[weekId] = newDevicesByCategories[weekId]
      ? [...newDevicesByCategories[weekId], { ...device, meetings: deviceMeetings }]
      : [{ ...device, meetings: deviceMeetings }];
  }
  return newDevicesByCategories;
}, { needToCall: [] });
