import { connect } from 'react-redux';
import { refreshView as refreshViewAction } from 'ra-core';
import useInterval from '../utils/useInterval';


const Refresher = ({ refreshView }) => {
  useInterval(() => {
    refreshView();
  }, 5 * 60 * 1000);
  return null;
};

export default connect(null, {
  refreshView: refreshViewAction,
})(Refresher);
