import React, {
  useState,
} from 'react';
import { DatePicker } from '@material-ui/pickers';
import { change } from 'redux-form';
import { FormDataConsumer } from 'react-admin';
import { Button } from '@material-ui/core';
import {
  Today,
} from '@material-ui/icons';
import moment from 'moment';


function BasicDatePicker(props) {
  const [selectedDate, handleDateChange] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FormDataConsumer>
      {({ formData, dispatch, ...rest }) => (
        <div>
          <Button onClick={() => setIsOpen(true)}>
            <Today />
          </Button>
          <DatePicker
            style={{ display: 'none' }}
            open={isOpen}
            autoOk
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onChange={(value) => {
              dispatch(change('filterForm', 'from', moment(value).startOf('week').format()));
              dispatch(change('filterForm', 'to', moment(value).endOf('week').format()));
              handleDateChange(value);
            }}
            value={selectedDate}
            showTodayButton
            todayLabel="Aujourd'hui"
          />
        </div>
      )}
    </FormDataConsumer>

  );
}

export default BasicDatePicker;
