export const FITTING_MEETING_TYPES = ['essayage', 'essayage_rx'];
export const DELIVERY_MEETING_TYPES = ['livraison'];

export const DeviceRadioTypes = [
  {
    id: 'Nothing',
    name: 'Aucune',
  },
  {
    id: 'DropboxRx',
    name: 'Rx Dropbox',
  },
  {
    id: 'PaperRx',
    name: 'Rx Papier',
  },
];

export const TypeChoices = [
  {
    id: 'ANTICYPHOSE',
    name: 'ANTICYPHOSE',
    codes: ['4601994468987556', '4512300366133760', '8190027662572892'],
    imageType: 'TRONC',
  },
  {
    id: 'BIVALVE lombaire',
    name: 'BIVALVE lombaire',
    codes: ['148832349044028', '4512300366133760', '8190027662572892'],
    imageType: 'TRONC',
  },
  {
    id: 'BIVALVE niv sternal',
    name: 'BIVALVE niv sternal',
    codes: ['1319674098008195', '4512300366133760', '8190027662572892', '7429697326651788'],
    imageType: 'TRONC',
  },
  {
    id: 'BIVALVE niv xyphoide',
    name: 'BIVALVE niv xyphoide',
    codes: ['5904810944784656', '4512300366133760', '8190027662572892'],
    imageType: 'TRONC',
  },
  {
    id: 'BIVALVE niv clavicule',
    name: 'BIVALVE niv clavicule',
    codes: ['697922536080986', '4512300366133760', '8190027662572892', '7429697326651788'],
    imageType: 'TRONC',
  },
  {
    id: 'BOTTE',
    name: 'BOTTE',
    codes: ['clf9gltqk02rj060536g1uwy9', '2640795732381294'],
    // dev codes
    // codes: ['7172676235597976', '2640795732381294'],
    imageType: 'JAMBE',
  },
  {
    id: 'BOTTE ARTICULEE',
    name: 'BOTTE ARTICULEE',
    codes: ['6126069060757164', '2640795732381294'],
    imageType: 'JAMBE',
  },
  {
    id: 'CARENE',
    name: 'CARENE',
    codes: ['5844326254129377', '4512300366133760', '8190027662572892'],
    imageType: 'TRONC',
  },
  {
    id: 'CORSET-MINERVE',
    name: 'CORSET-MINERVE',
    codes: ['3962248309787062', '2111222383469782', '8190027662572892'],
    imageType: 'TETE',
  },
  {
    id: 'CTM',
    name: 'CTM',
    codes: ['6635405214428377', '4512300366133760', '8190027662572892'],
    imageType: 'TRONC',
  },
  {
    id: 'GENOUILLERE',
    name: 'GENOUILLERE',
    codes: ['3549093332523663', '1920775375475568'],
    imageType: 'JAMBE',
  },
  {
    id: 'HEMICULOTTE',
    name: 'HEMICULOTTE',
    codes: ['4312779728829594', '394085629725275'],
    imageType: 'TRONC',
  },
  {
    id: 'MINERVE',
    name: 'MINERVE',
    codes: ['4367404373630369', '2111222383469782'],
    imageType: 'TETE',
  },
  {
    id: 'MINERVE INDIENNE',
    name: 'MINERVE INDIENNE',
    codes: ['4593820183474841', '2111222383469782'],
    imageType: 'TETE',
  },
  {
    id: 'MINERVE THORACIQUE',
    name: 'MINERVE THORACIQUE',
    codes: ['3086291114684182', '2111222383469782'],
    imageType: 'TETE',
  },
  {
    id: 'MILWAUKEE',
    name: 'MILWAUKEE',
    codes: ['2579025429625643', '2111222383469782', '8190027662572892'],
    imageType: 'TRONC',
  },
  {
    id: 'MONOVALVE lombaire',
    name: 'MONOVALVE lombaire',
    codes: ['148832349044028', '4512300366133760', '8190027662572892'],
    imageType: 'TRONC',
  },
  {
    id: 'MONOVALVE thoracique',
    name: 'MONOVALVE thoracique',
    codes: ['1319674098008195', '4512300366133760', '8190027662572892', '2251980698672290'],
    imageType: 'TRONC',
  },
  {
    id: 'PELVI PEDIEUX',
    name: 'PELVI PEDIEUX',
    codes: ['7002801740078842', '1043513982682965'],
    // dev codes
    // codes: ['7002801740078842', '8057624715317623'],
    imageType: 'JAMBE',
  },
  {
    id: 'RELEVEUR',
    name: 'RELEVEUR',
    codes: ['542000640387284', '2640795732381294'],
    imageType: 'JAMBE',
  },
  {
    id: 'SARMIENTO',
    name: 'SARMIENTO',
    codes: ['2325392006786028', '2640795732381294', '6904595696980348'],
    imageType: 'JAMBE',
  },
  {
    id: 'SARMIENTO BRAS',
    name: 'SARMIENTO BRAS',
    codes: ['5949781417468920', '1346860718735305'],
    imageType: 'TRONC',
  },
];

export const ColorChoices = [
  { id: 'BABY BORD DE MER', name: 'BABY BORD DE MER' },
  { id: 'BLANC', name: 'BLANC' },
  { id: 'BLEU', name: 'BLEU' },
  { id: 'CAMOUFLAGE', name: 'CAMOUFLAGE' },
  { id: 'CARBONE BLEU', name: 'CARBONE BLEU' },
  { id: 'CARBONE NOIR', name: 'CARBONE NOIR' },
  { id: 'CHEVAUX', name: 'CHEVAUX' },
  { id: 'COEUR', name: 'COEUR' },
  { id: 'CRAZY BEARS', name: 'CRAZY BEARS' },
  { id: 'FILLETTE AUX COQUELICOTS', name: 'FILLETTE AUX COQUELICOTS' },
  { id: 'FLEURS SIXTIES', name: 'FLEURS SIXTIES' },
  { id: 'FLOWER POWER', name: 'FLOWER POWER' },
  { id: 'FOOT', name: 'FOOT' },
  { id: 'FROISSE BLEU', name: 'FROISSE BLEU' },
  { id: "GOUTTES D'EAU", name: "GOUTTES D'EAU" },
  { id: "JEAN'S", name: "JEAN'S" },
  { id: "JEAN'S VTP", name: "JEAN'S VTP" },
  { id: 'LEOPARD', name: 'LEOPARD' },
  { id: 'LES ANIMAUX', name: 'LES ANIMAUX' },
  { id: 'LOVE TATOOS', name: 'LOVE TATOOS' },
  { id: 'MARINIERE', name: 'MARINIERE' },
  { id: 'MUSIQUE ORANGE', name: 'MUSIQUE ORANGE' },
  { id: 'NOIR', name: 'NOIR' },
  { id: 'PAPILLONS BLEU', name: 'PAPILLONS BLEU' },
  { id: 'PRINTEMPS JAPONNAIS', name: 'PRINTEMPS JAPONNAIS' },
  { id: 'PAPILLONS ROSE', name: 'PAPILLONS ROSE' },
  { id: 'PEACE AND LOVE', name: 'PEACE AND LOVE' },
  { id: 'PIRATE', name: 'PIRATE' },
  { id: 'PRINCESSE', name: 'PRINCESSE' },
  { id: 'SKULL FLOWER', name: 'SKULL FLOWER' },
  { id: "SPORTS D'EQUIPE", name: "SPORTS D'EQUIPE" },
  { id: 'SPORTS MECANIQUES BLEU', name: 'SPORTS MECANIQUES BLEU' },
  { id: 'SPORTS MECANIQUES ROUGE', name: 'SPORTS MECANIQUES ROUGE' },
  { id: 'TATOO BLEU', name: 'TATOO BLEU' },
];

export const FoamChoices = [
  { id: 'BLANC', name: 'BLANC' },
  { id: 'BLEU', name: 'BLEU' },
  { id: 'BLEU CIEL', name: 'BLEU CIEL' },
  { id: 'BLEU ELECTRIQUE', name: 'BLEU ELECTRIQUE' },
  { id: 'NOIR', name: 'NOIR' },
  { id: 'ROSE', name: 'ROSE' },
  { id: 'ROUGE', name: 'ROUGE' },
  { id: 'VERT', name: 'VERT' },
  { id: 'VIOLET', name: 'VIOLET' },
];

export const ImageTypeChoices = [
  { id: 'TETE', name: 'TETE' },
  { id: 'TRONC', name: 'TRONC' },
  { id: 'JAMBE', name: 'JAMBE' },
  { id: 'CORSET SIEGE', name: 'CORSET SIEGE' },
  { id: 'VERTICALISATEUR', name: 'VERTICALISATEUR' },
];
