import React from 'react';
import CalendarDay from './CalendarDay';

function getHoursDivs(firstHour, lastHour) {
  const hourDivs = [];
  for (let index = firstHour; index <= lastHour; index += 1) {
    hourDivs.push(<div key={`${index}h`}>{`${index}h`}</div>);
  }
  return hourDivs;
}

const Calendar = (props) => {
  const { calendar, isDayView } = props;
  console.log(calendar);
  const calendarRowStyle = { display: 'flex', flexDirection: 'row' };
  const calendarHoursStyle = {
    display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '100px', height: '600px',
  };
  return (
    <div>
      <div style={calendarRowStyle}>
        <div style={calendarHoursStyle}>
          {getHoursDivs(8, 20)}
        </div>
        {Object.keys(calendar).slice(0, 3).map(date => (
          <CalendarDay key={date} date={date} userDays={calendar[date]} isDayView={isDayView} />
        ))}
      </div>
      {Object.keys(calendar).length > 3
      && (
      <div style={calendarRowStyle}>
        <div style={calendarHoursStyle}>
          {getHoursDivs(8, 20)}
        </div>
        {Object.keys(calendar).slice(3, 6).map(date => (
          <CalendarDay key={date} date={date} userDays={calendar[date]} isDayView={isDayView} />
        ))}
      </div>
      )
      }
    </div>
  );
};

export default Calendar;
