import React from 'react';
import {
  CardContent,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Moment from 'moment';

function getMeetingListDivSizes(hoursHeight, firstHour, lastHour, startAt, finishAt) {
  const date = new Moment(startAt);
  const finishDate = new Moment(finishAt);
  const minuteHeight = (hoursHeight - 16) / ((lastHour - firstHour) * 60);
  const dateMinutes = (date.hours() - firstHour) * 60 + date.minutes();
  const meetingMinutes = Moment.duration(finishDate.diff(date)).as('minutes');
  return {
    top: `${dateMinutes * minuteHeight}px`,
    height: `${meetingMinutes * minuteHeight}px`,
  };
}

const CalendarMeetingList = (props) => {
  const { meetingList, dayInfos: { height, firstHour, lastHour } } = props;
  return (
    <Link
      style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', textDecoration: 'none', background: '#006494', width: '100%', position: 'absolute', ...getMeetingListDivSizes(height, firstHour, lastHour, meetingList.startAt, meetingList.finishAt),
      }}
      to={{
        pathname: `/MeetingList/${meetingList.id}/show`,
      }}
    >
      <CardContent style={{ padding: '1rem' }}>
        <p style={{ margin: 0, fontSize: '1.1rem' }}>{meetingList.place.name}</p>
        {meetingList.doctors && meetingList.doctors.map(doctor => <p style={{ margin: 0, fontSize: '0.9rem', textTransform: 'capitalize' }}>{doctor.name}</p>)}
        {/* <p style={{ margin: 0 }}>{`${new Moment(meetingList.startAt).format('H[h]mm')} - ${new Moment(meetingList.finishAt).format('H[h]mm')}`}</p> */}
      </CardContent>
    </Link>
  );
};

export default CalendarMeetingList;
