import {
  AUTH_LOGIN, AUTH_ERROR, AUTH_CHECK, AUTH_LOGOUT, AUTH_GET_PERMISSIONS,
} from 'react-admin';
import axios from 'axios';

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    return axios.request({
      method: 'post',
      withCredentials: true,
      url: process.env.REACT_APP_API_URL,
      data: { query: `mutation {login(email:"${username}", password: "${password}"){id, name, email, type}}` },
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => {
      const { data } = response;
      if (response.status < 200 || response.status >= 300 || data.errors) {
        throw new Error(data.errors[0].message);
      }
      localStorage.setItem('logged', 'true');
      localStorage.setItem('loggedUser', JSON.stringify(data.data.login));
      return data;
    });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('logged');
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('logged');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    if (!localStorage.getItem('logged')) {
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
    return loggedUser.type;
  }
  return Promise.reject(new Error('Unknown method'));
};
