import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Field, blur,
} from 'redux-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Moment from 'moment';

class MyDateInput extends Component {
  constructor(props) {
    super(props);
    let birthdate = null;
    if (props.birthdate || props.record.birthdate) {
      birthdate = props.birthdate ? new Moment(props.birthdate) : new Moment(props.record.birthdate);
    }
    this.state = {
      birthdate,
    };
  }

  componentDidMount() {
    this.handleChange = this.handleChange.bind(this);
    this.handleChange('birthdate', this.state.birthdate);
  }

  handleChange(key, value) {
    const payload = {};
    payload[key] = value;
    this.setState(payload, () => {
      const { birthdate } = this.state;
      this.props.dispatch(blur('record-form', 'birthdate', new Moment(birthdate).format()));
    });
  }

  render() {
    const { birthdate } = this.state;
    return (
      <span>
        <KeyboardDatePicker
          variant="inline"
          label="Date de naissance"
          format="DD/MM/YYYY"
          placeholder="08/06/2001"
          value={birthdate}
          onChange={value => this.handleChange('birthdate', value)}
          margin="normal"
          required
          helperText="Champs requis"
        />
        <Field name="birthdate" component="input" style={{ display: 'none' }} />
      </span>
    );
  }
}

export default connect()(MyDateInput);
