import React from 'react';
import { DragSource } from 'react-dnd';
import { withDataProvider, UPDATE } from 'react-admin';
import Button from '@material-ui/core/Button';

export const ItemTypes = {
  MEETING: 'meeting',
};

const meetingSource = {
  beginDrag() {
    return {};
  },
  endDrag({ meeting, dataProvider }, monitor) {
    if (!monitor.getDropResult()) {
      return {};
    }
    const { schedule } = monitor.getDropResult();
    const updatedRecord = { startAt: schedule.start, finishAt: schedule.end, id: meeting.id };
    dataProvider(UPDATE, 'Meeting', { data: updatedRecord }, {
      onSuccess: {
        notification: { body: `Rdv déplacé à ${schedule.start.format('H[h]mm')}`, level: 'info' },
        refresh: true,
      },
      onError: {
        notification: { body: 'Erreur: Le déplacement du rdv a échoué', level: 'warning' },
      },
    });
    return {};
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

const meetingColors = {
  essayage: '#ED3F7D',
  essayage_rx: '#F3DBE3',
  retouche: '#4F7D7F',
  prise_de_mesure: '#ED3B18',
  livraison: '#F2864A',
  controle: '#FFFB48',
};

function Meeting({
  meeting, setModalRecordId, setModalLabel, setIsModalOpen, connectDragSource, isDragging, dataProvider, record,
}) {
  if (meeting.comment && meeting.comment.length > 25) {
    meeting.comment = `${meeting.comment.substring(0, 25)}...`;
  }
  return connectDragSource(
    <div
      style={{
        flexGrow: 1,
        flexBasis: 0,
        marginRight: '2px',
        marginLeft: '2px',
        display: 'flex',
        transform: 'translate3d(0, 0, 0)',
      }}
    >
      <Button
        style={{
          background: meeting.status === 'Seen' ? 'grey' : meetingColors[meeting.type],
          flexGrow: 1,
          opacity: meeting.status === 'Seen' ? 0.65 : 0.87,
          flexBasis: 0,
          fontWeight: 'bold',
          color: 'black',
          textDecoration: ['Excused', 'NotExcused'].includes(meeting.status) ? 'line-through' : 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          textTransform: 'none',
          overflow: 'hidden',
        }}
        onClick={() => { setModalRecordId(meeting.id); setModalLabel(`Consultation de ${meeting.patient.lastname} ${meeting.patient.firstname}`); setIsModalOpen(true); }}
      >
        {`${meeting.patient.lastname} ${meeting.patient.firstname}`}
        {meeting.device && <span style={{ marginLeft: '0.8rem' }}>{`(${meeting.device.type})`}</span> }
        {meeting.comment && <span style={{ fontWeight: 'normal', marginLeft: '0.8rem' }}>{`${meeting.comment}`}</span> }
      </Button>
    </div>,
  );
}

export default withDataProvider(DragSource(ItemTypes.MEETING, meetingSource, collect)(Meeting));
