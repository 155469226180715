import React, { Component } from 'react';
import 'moment/locale/fr';
import {
  Edit, TextField, DateField, required, AutocompleteInput, Show, FormDataConsumer, ReferenceArrayInput, LongTextInput, ArrayField, ChipField, SimpleShowLayout, Create, SingleFieldList, SimpleForm, ReferenceInput, EditButton, SelectArrayInput,
} from 'react-admin';
import Moment from 'moment';
import styled from 'styled-components';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import DateTwoTimeInput from '../DateTwoTimeInput';
import MeetingListShowMeetings from './MeetingListShowMeetings';


Moment.locale('fr');

class SelectDoctorInput extends Component {
  constructor(props) {
    super(props);
    this.state = { choices: [] };
  }

  componentDidMount() {
    const self = this;
    SelectDoctorInput.getDoctorsFor(this.props.placeId)
      .then(choices => self.setState({ choices }));
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.placeId !== prevProps.placeId) {
      const self = this;
      SelectDoctorInput.getDoctorsFor(this.props.placeId)
        .then(choices => self.setState({ choices }));
    }
  }

  static getDoctorsFor(placeId) {
    const updatedRecord = { operationName: 'meetingPlace', variables: { id: placeId }, query: `query meetingPlace{meetingPlace(id: "${placeId}"){doctors{id, name}}}` };
    return fetch(process.env.REACT_APP_API_URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedRecord),
    })
      .then(response => response.json())
      .then(({ data }) => data.meetingPlace.doctors)
      .catch((e) => {
        console.error(e);
        return [];
      });
  }

  render() {
    const { source, placeId, ...rest } = this.props;
    const choices = this.state.choices ? this.state.choices : [];
    return (
      <SelectArrayInput
        source={source}
        choices={choices}
        {...rest}
      />
    );
  }
}

export const MeetingListCreate = props => (
  <Create {...props} title="Création liste de consultations">
    <SimpleForm redirect="show">
      <DateTwoTimeInput />
      <ReferenceInput label="Lieux" source="placeId" reference="MeetingPlace" validate={required()} perPage={100}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => (formData.placeId
    && (
    <SelectDoctorInput
      source="doctorIds"
      label="Médecin"
      placeId={formData.placeId}
      {...rest}
    />
    )
        )}
      </FormDataConsumer>
      <ReferenceArrayInput label="Opérateurs" source="operatorIds" reference="User" filter={{ type: 'Operator' }} validate={required()}>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <LongTextInput label="Annotations" source="comment" />
    </SimpleForm>
  </Create>
);

const FullNameField = ({ record = {} }) => (
  <span>
    {record.patient.firstname}
    &nbsp;
    {record.patient.lastname}
  </span>
);
FullNameField.defaultProps = { label: 'Patient' };

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const PostShowActions = ({ basePath, data, resource }) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <CardActions>
      <Button
        component={Link}
        to={{
          pathname: '/CalendarDay',
        }}
      >
        <ArrowBack />
Calendrier
      </Button>
    </CardActions>
    <CardActions style={cardActionStyle}>
      <EditButton basePath={basePath} record={data} style={{ float: 'left' }} />
    </CardActions>
  </div>

);

// const TimeField = ({ source, record = {} }) => <span>{new Moment(record[source]).format('H[h]mm')}</span>;


const SimpleShowLayoutStyled = styled(SimpleShowLayout)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div:last-child {
    width: 100%;
}
`;

export const MeetingListShow = (({ ...props }) => (
  <Show actions={<PostShowActions />} {...props} title="Liste de consultation">
    <SimpleShowLayoutStyled>
      <DateField label="Début" source="startAt" locales="fr-FR" />
      <TextField label="Lieux" source="place.name" />
      <ArrayField label="Médecins" source="doctors">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Opérateurs" source="operators">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <TextField label="Annotations" source="comment" />
      <MeetingListShowMeetings source="meetings" />
    </SimpleShowLayoutStyled>
  </Show>
));

export const MeetingListEdit = props => (
  <Edit {...props} title="Édition liste de consultations">
    <SimpleForm redirect="show">
      <DateTwoTimeInput source="startDate" />
      <ReferenceArrayInput label="Opérateurs" parse={operators => operators.map(id => ({ id }))} format={(operators) => { if (operators.length > 0 && operators[0].id) { console.log(operators.map(operator => operator.id)); return operators.map(operator => operator.id); } return operators; }} filter={{ type: 'Operator' }} source="operators" reference="User" allowEmpty>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <LongTextInput label="Annotations" source="comment" />
    </SimpleForm>
  </Edit>
);
