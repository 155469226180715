import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification, UPDATE } from 'react-admin';
import { push } from 'react-router-redux';
import dataProviderSingleton from '../dataProvider';

class AbortMeetingButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { push: reduxPush, record, showNotification: reduxShowNotification } = this.props;
    dataProviderSingleton.get().then(dataProvider => dataProvider(UPDATE, 'Meeting', { data: { id: record.id, isActive: false } })
      .then(() => {
        reduxShowNotification('Rdv annulé');
        reduxPush('/CalendarDay');
      })
      .catch((e) => {
        console.error(e);
        reduxShowNotification('Erreur: Rdv non annulé', 'warning');
      }));
    // const updatedRecord = { operationName: 'updateMeeting', variables: { id: record.id }, query: `mutation updateMeeting{updateMeeting(id: "${record.id}", isActive: false){id}}` };
    // fetch(process.env.REACT_APP_API_URL, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(updatedRecord),
    // })
    //   .then(() => {
    //     reduxShowNotification('Rdv annulé');
    //     // reduxPush(`/MeetingList/${record.id}/show`);
    //     reduxPush('/MeetingList');
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //     reduxShowNotification('Erreur: Rdv non annulé', 'warning');
    //   });
  }

  render() {
    return (
      <Button
        style={{ color: '#f44336' }}
        onClick={this.handleClick}
      >
Annuler le RDV
      </Button>
    );
  }
}

AbortMeetingButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(null, {
  showNotification,
  push,
})(AbortMeetingButton);
