import React from 'react';
import {
  AppBar, UserMenu, translate,
} from 'react-admin';

const CustomUserMenu = translate(({ translate, ...props }) => {
  let name = '';
  if (localStorage.getItem('loggedUser')) {
    if (JSON.parse(localStorage.getItem('loggedUser'))) {
      name = JSON.parse(localStorage.getItem('loggedUser')).name;
    }
  }
  return (
    <UserMenu {...props}>
      <div>{name}</div>
    </UserMenu>
  );
});

const CustomAppBar = props => (
  <AppBar {...props} userMenu={<CustomUserMenu />} />
);

export default CustomAppBar;
