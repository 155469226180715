import React from 'react';
import CalendarMeetingList from './CalendarMeetingList';
import CalendarMeeting from './CalendarMeeting';
import CalendarUnavailability from './CalendarUnavailability';

const CalendarUserDay = (props) => {
  const {
    username, meetingLists, meetings, isDayView, userUnavailabilities, date,
  } = props;
  const userDayDivStyle = {
    position: 'relative', flexGrow: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '0.1rem', marginRight: '0.1rem',
  };
  const userDayDivHeaderStyle = {
    color: 'white', background: '#13293D', position: 'absolute', top: '-2.2rem', left: 0, width: '100%', paddingTop: '0.5rem', paddingBottom: '0.5rem',
  };
  return (
    <div style={userDayDivStyle}>
      <div style={userDayDivHeaderStyle}>
        {username}
      </div>
      {userUnavailabilities && userUnavailabilities.map(unavailability => (
        <CalendarUnavailability
          key={unavailability.startAt}
          unavailability={unavailability}
          isDayView={isDayView}
          dayInfos={{
            height: 600, firstHour: 8, lastHour: 20, date,
          }}
        />
      ))}
      {meetingLists && meetingLists.map(meetingList => <CalendarMeetingList key={meetingList.startAt} meetingList={meetingList} dayInfos={{ height: 600, firstHour: 8, lastHour: 20 }} />)}
      {meetings && meetings.map(meeting => <CalendarMeeting key={meeting.startAt} meeting={meeting} isDayView={isDayView} dayInfos={{ height: 600, firstHour: 8, lastHour: 20 }} />)}
    </div>
  );
};

export default CalendarUserDay;
